import { Link as LinkScroll } from 'react-scroll';
import cx from 'classnames';
import Link from 'next/link';
import { forwardRef } from 'react';

import Typography from '#app/UI/atoms/typography/typography';
import IconArrowButton from '#app/UI/atoms/icons/arrowButton';
import { scrollToOffsetSize } from '#helpers/scroll-to';

import styles from './index.module.scss';

const Btn = forwardRef(
  (
    {
      buttonType, // button || a
      variant: btnVariant,
      type,
      href,
      onClick,
      children,
      width,
      height,
      target,
      backgroundColor,
      textOptions: {
 variant, fontSize, lineHeight, fontWeight, color, underline 
},
      center,
      iconLeft,
      iconRight,
      iconArrow,
      iconArrowBig,
      testid,
      className
    },
    ref
  ) => {
    switch (buttonType) {
      case 'button':
        return (
          <button
            className={cx(styles.button, className, { [styles[btnVariant]]: btnVariant })}
            data-testid={testid}
            type={type}
            ref={ref}
            href={href}
            onClick={onClick}
            target={target || null}
            style={{
              width,
              height,
              background: backgroundColor,
              margin: center && '0 auto'
            }}
          >
            {iconLeft}
            <Typography
              variant={variant}
              fontSize={fontSize}
              lineHeight={lineHeight}
              fontWeight={fontWeight}
              color={color}
              tag="p"
            >
              {children}
            </Typography>
            {iconRight}{' '}
            {iconArrow || iconArrowBig ? (
              <div
                className={cx(
                  styles.buttonArrow,
                  styles[`icon_${iconArrow}`],
                  styles[iconArrowBig ? `iconbig_${iconArrowBig}` : '']
                )}
              >
                <IconArrowButton />
              </div>
            ) : null}
          </button>
        );
      case 'a':
        return (
          <a
            className={cx(styles.button, className, { [styles[btnVariant]]: btnVariant })}
            data-testid={testid}
            type={type}
            ref={ref}
            href={href}
            onClick={onClick}
            target={target || null}
            style={{
              width,
              height,
              background: backgroundColor,
              margin: center && '0 auto'
            }}
          >
            {iconLeft}
            <Typography
              variant={variant}
              fontSize={fontSize}
              lineHeight={lineHeight}
              fontWeight={fontWeight}
              color={color}
              tag="p"
              underline={underline || false}
            >
              {children}
            </Typography>
            {iconRight}{' '}
            {iconArrow || iconArrowBig ? (
              <div
                className={cx(
                  styles.buttonArrow,
                  styles[`icon_${iconArrow}`],
                  styles[iconArrowBig ? `iconbig_${iconArrowBig}` : '']
                )}
              >
                <IconArrowButton />
              </div>
            ) : null}
          </a>
        );

      default:
        return (
          <button
            className={cx(styles.button, className, { [styles[btnVariant]]: btnVariant })}
            data-testid={testid}
            type={type}
            ref={ref}
            href={href}
            onClick={onClick}
            target={target || null}
            style={{
              width,
              height,
              background: backgroundColor,
              margin: center && '0 auto'
            }}
          >
            {iconLeft}
            <Typography
              variant={variant}
              fontSize={fontSize}
              lineHeight={lineHeight}
              fontWeight={fontWeight}
              color={color}
              tag="p"
            >
              {children}
            </Typography>
            {iconRight}{' '}
            {iconArrow || iconArrowBig ? (
              <div
                className={cx(
                  styles.buttonArrow,
                  styles[`icon_${iconArrow}`],
                  styles[iconArrowBig ? `iconbig_${iconArrowBig}` : '']
                )}
              >
                <IconArrowButton />
              </div>
            ) : null}
          </button>
        );
    }
  }
);

const Button = ({
  variant, // black || white || popup || lila || white-outline || aqua || skyblue || black-outline
  type, // scroll || redirect || url || submit
  href,
  scrollOptions, // { to, offset }
  buttonType = 'a', // button || a
  children,
  width,
  height,
  backgroundColor,
  textOptions,
  target,
  onClick,
  center,
  iconLeft,
  iconRight,
  iconArrow,
  iconArrowBig,
  testid,
  className
}) => {
  switch (type) {
    case 'scroll':
      return (
        <LinkScroll
          to={scrollOptions.to}
          smooth
          duration={1000}
          offset={scrollToOffsetSize(scrollOptions.offset)}
          style={{
            width,
            height
          }}
        >
          <Btn
            buttonType={buttonType}
            // ---------------------
            target={target}
            variant={variant}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            textOptions={textOptions}
            center={center}
            onClick={onClick}
            iconLeft={iconLeft}
            iconRight={iconRight}
            iconArrow={iconArrow}
            iconArrowBig={iconArrowBig}
            className={className}
            testid={testid}
          >
            {children}
          </Btn>
        </LinkScroll>
      );
    case 'url':
      return (
        <Link href={href} passHref={true}>
          <Btn
            buttonType={buttonType}
            // ---------------------
            target={target}
            variant={variant}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            textOptions={textOptions}
            center={center}
            onClick={onClick}
            iconLeft={iconLeft}
            iconRight={iconRight}
            iconArrow={iconArrow}
            iconArrowBig={iconArrowBig}
            className={className}
            testid={testid}
          >
            {children}
          </Btn>
        </Link>
      );
    case 'redirect':
      return (
        <Link href={href}>
          <Btn
            buttonType={buttonType}
            // ---------------------
            target={target}
            variant={variant}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            textOptions={textOptions}
            center={center}
            onClick={onClick}
            iconLeft={iconLeft}
            iconRight={iconRight}
            iconArrow={iconArrow}
            iconArrowBig={iconArrowBig}
            className={className}
            testid={testid}
          >
            {children}
          </Btn>
        </Link>
      );
    case 'submit':
      return (
        <Btn
          type="submit"
          buttonType="button"
          // ---------------------
          target={target}
          variant={variant}
          width={width}
          height={height}
          backgroundColor={backgroundColor}
          textOptions={textOptions}
          center={center}
          onClick={onClick}
          iconLeft={iconLeft}
          iconRight={iconRight}
          iconArrow={iconArrow}
          iconArrowBig={iconArrowBig}
          className={className}
          testid={testid}
        >
          {children}
        </Btn>
      );

    default:
      return (
        <Btn
          buttonType={buttonType}
          // ---------------------
          target={target}
          variant={variant}
          width={width}
          height={height}
          backgroundColor={backgroundColor}
          textOptions={textOptions}
          center={center}
          onClick={onClick}
          iconLeft={iconLeft}
          iconRight={iconRight}
          iconArrow={iconArrow}
          iconArrowBig={iconArrowBig}
          className={className}
          testid={testid}
        >
          {children}
        </Btn>
      );
  }
};

export default Button;
