export const toPX = (isDesktop, name) => {
  if (name[0] === '100%') {
    return isDesktop ? `${name[0]}` : `${name[1]}px`;
  } else if (name[1] === '100%') {
    return isDesktop ? `${name[0]}px` : `${name[1]}`;
  } else if (name[0] === '100%' && name[1] === '100%') {
    return isDesktop ? `${name[0]}` : `${name[1]}`;
  } else {
    return isDesktop ? `${name[0]}px` : `${name[1]}px`;
  }
};

const toPXSimple = (name, subname, defVal) => {
  return name[subname] ? [`${name[subname][0]}px`, `${name[subname][1]}px`] : defVal;
};

export const toPXCombo = (isDesktop, name) => {
  if (name) {
    const defVal = ['0px', '0px'];
    const top = toPXSimple(name, 'top', defVal);
    const right = toPXSimple(name, 'right', defVal);
    const bottom = toPXSimple(name, 'bottom', defVal);
    const left = toPXSimple(name, 'left', defVal);

    const desktop = `${top[0]} ${right[0]} ${bottom[0]} ${left[0]}`;
    const mobile = `${top[1]} ${right[1]} ${bottom[1]} ${left[1]}`;

    return isDesktop ? desktop : mobile;
  }
};
