import cx from 'classnames';
import { create } from 'zustand';
import dynamic from 'next/dynamic';
import { parseISO, format } from 'date-fns';
import { useLayoutEffect, useRef } from 'react';

import { Link } from '#atoms/index';
import { eventGtag } from '#app/hooks/eventGtag';
import Button from '#app/UI/molecules/new-button';
import useUserContext from '#hooks/useUserContext';
import useWindowSize from '#app/hooks/useWindowSize';

const Typography = dynamic(() => import('#app/UI/atoms/new-typography'));

export const useAnnouncementBarStatus = create(set => ({
  isHidden: false,
  height: 60,
  setIsHidden: status => set({ isHidden: status }),
  setHeight: status => set({ height: status })
}));

const data = {
  global: [
    {
      from: '2023-12-11',
      until: '2023-12-23',
      text: '🎄✨ Celebra NAVIDAD con aprendizaje. 🎅🎁 ¡40% OFF en TODAS las RUTAS! Código:',
      coupon: 'SANTA40',
      link: '¡Aprovechar oferta!',
      href: '/rutas?utm_source=home&utm_medium=navbar&utm_campaign=navidad'
    },
    {
      from: '2023-12-24',
      until: '2024-01-11',
      text: '🌟✨ Recibe el NUEVO AÑO con conocimiento. 🎉🎁 ¡45% OFF en TODAS las RUTAS! Código:',
      coupon: 'FIESTAS45',
      link: '¡Accede ahora!',
      href: '/rutas?utm_source=home&utm_medium=navbar&utm_campaign=propositos'
    },
    {
      from: '2024-01-24',
      until: '2024-01-31',
      text: '🌴✨ VACACIONES TECH 🚀🎁 ¡40% OFF en rutas! Código:',
      coupon: 'VACACIONES40',
      link: '¡Aprovechar oferta!',
      href: '/rutas?utm_source=home&utm_medium=navbar&utm_campaign=vacacionestech'
    },
    {
      from: '2024-02-22',
      until: '2024-02-29',
      text: '🚀 Aprovecha esta semana de descuentos ¡40% OFF! Código:',
      coupon: 'CODER40',
      link: '¡Aprovechar oferta!',
      href: '/rutas?utm_source=home&utm_medium=navbar&utm_campaign=vacacionestech'
    },
    {
      from: '2024-03-15',
      until: '2024-03-22',
      text: '🚀👉¡Llegaron los Cyber Days! No dejes pasar esta oportunidad de 45% OFF en TODAS las rutas. Código:',
      coupon: 'CYBER45',
      link: '¡Aprovechar oferta!',
      href: '/rutas?utm_source=web&utm_medium=CyberDays&utm_campaign=B2C'
    },
    {
      from: '2024-04-15',
      until: '2024-04-26',
      text: '🚀 ¡CYBER WOW! Aprovecha esta semana de descuentos ¡35% OFF!',
      coupon: null,
      link: '¡Aprovechar oferta!',
      href: '/rutas?utm_source=web&utm_medium=CyberWoW&utm_campaign=B2C'
    }
  ],
  MXN: {
    text: '¡Aprovecha el 35% OFF en rutas de aprendizaje por tiempo limitado! 💥💻 ¡Inicio Clases Recargad@ con Crack The Code! ⏳🎉',
    link: '¡Accede ya!',
    href: '/rutas?utm_source=crackthecode.la&utm_medium=notifbar&utm_campaign=back_to_school_ago_2023'
  }
};

const AnnouncementBar = () => {
  const componenteRef = useRef(null);
  const { isHidden, setIsHidden, setHeight } = useAnnouncementBarStatus();
  const { data: userData } = useUserContext();
  const { isDesktop } = useWindowSize();

  const campaigns = data.global;
  const today = format(new Date(), 'yyyy-MM-dd');

  let activeCampaign = null;

  for (const campaign of campaigns) {
    const fromDate = format(parseISO(campaign.from), 'yyyy-MM-dd');
    const untilDate = format(parseISO(campaign.until), 'yyyy-MM-dd');

    if (today >= fromDate && today <= untilDate) {
      activeCampaign = campaign;
      break;
    }
  }

  useLayoutEffect(() => {
    if (componenteRef.current) {
      setHeight(componenteRef.current.offsetHeight); // Enviar altura del componente
    }

    if (!activeCampaign) {
      setIsHidden(true); // Ocultar si la fecha actual no tiene ninguna campaña disponible dentro del rango
    }
  }, []);

  const handleCloseAnnouncementBar = () => {
    setIsHidden(true);
    eventGtag('close_notif_bar', userData);
  };

  const events = () => {
    eventGtag('CTA_notif_bar', userData);
  };

  return (
    <section
      className={cx('announcement-bar-Wrapper', {['announcement-bar-Wrapper__hidden']: isHidden})}
      ref={componenteRef}
    >
      <div className="announcement-bar">
        {/* Text Left */}
        {isDesktop ? (
          <div className="announcement-bar_text-left">
            <Typography
              className="typo-18 sm_typo-14 text-white text-center"
              tag="p"
              style={{ color: '#DEA8FF' }}
            >
              <b>{activeCampaign?.text || 'default text'}</b>{' '}
              <b style={{ color: '#DEA8FF' }}>{activeCampaign?.coupon}</b>{' '}
            </Typography>
          </div>
        ) : (
          <Link href={activeCampaign?.href || '/'}>
            <div className="announcement-bar_text-left">
              <Typography
                className="typo-18 sm_typo-14 text-white text-center"
                tag="p"
                style={{ color: '#DEA8FF' }}
              >
                <b>{activeCampaign?.text || 'default text'}</b>{' '}
                <b style={{ color: '#DEA8FF' }}>{activeCampaign?.coupon}</b>{' '}
              </Typography>
            </div>
          </Link>
        )}

        {/* Text Right */}
        <div className="announcement-bar_text-right">
          <Button
            type="url"
            variant="transparent"
            href={activeCampaign?.href || '/'}
            onClick={() => events()}
            width="auto"
            height="26px"
            textOptions={{
              fontSize: [18, 18],
              color: '#ffffff',
              underline: true
            }}
            iconArrow="lila"
          >
            {activeCampaign?.link || 'default link'}
          </Button>
        </div>

        {/* Close Icon */}
        <div
          className="announcement-bar_close-icon"
          onClick={() => handleCloseAnnouncementBar()}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default AnnouncementBar;
