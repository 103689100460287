import { toPX, toPXCombo } from '#app/helpers/toPX';
import { useWindowSize } from '#hooks/index';

const getStyles = ({
  variant,
  isDesktop,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  underline,
  margin,
  center
}) => {
  const fontWeightPx = fontWeight;
  const marginPx = toPXCombo(isDesktop, margin);
  const marginTopPx = margin && margin.top && toPX(isDesktop, margin.top);
  const marginRightPx = margin && margin.right && toPX(isDesktop, margin.right);
  const marginBottomPx = margin && margin.bottom && toPX(isDesktop, margin.bottom);
  const marginLeftPx = margin && margin.left && toPX(isDesktop, margin.left);
  const centerPx = center ? 'center' : null;

  if (!variant) {
    const fontSizePx = toPX(isDesktop, fontSize);
    const lineHeightPx = toPX(isDesktop, lineHeight);

    return {
      fontSize: fontSizePx,
      lineHeight: lineHeightPx,
      fontWeight: fontWeightPx,
      textAlign: centerPx,
      textDecoration: underline && 'underline',
      color,
      // Margin
      marginTop: marginTopPx,
      marginRight: marginRightPx,
      marginBottom: marginBottomPx,
      marginLeft: marginLeftPx,
      margin: marginPx
    };
  }

  return {
    fontWeight: fontWeightPx,
    textAlign: centerPx,
    textDecoration: underline && 'underline',
    color,
    // Margin
    marginTop: marginTopPx,
    marginRight: marginRightPx,
    marginBottom: marginBottomPx,
    marginLeft: marginLeftPx,
    margin: marginPx
  };
};

const Typography = ({
  children,
  tag,
  variant,
  fontSize = [14, 14],
  lineHeight = [24, 24],
  fontWeight = 400,
  color,
  underline,
  margin,
  center,
  ...rest
}) => {
  const { isDesktop } = useWindowSize();
  const stylesProps = {
    variant,
    isDesktop,
    fontSize,
    lineHeight,
    fontWeight,
    color,
    underline,
    margin,
    center
  };

  switch (tag) {
    case 'h1':
      return (
        <h1 className={variant || null} style={getStyles(stylesProps)} {...rest}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={variant || null} style={getStyles(stylesProps)} {...rest}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={variant || null} style={getStyles(stylesProps)} {...rest}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={variant || null} style={getStyles(stylesProps)} {...rest}>
          {children}
        </h4>
      );
    case 'p':
      return (
        <p className={variant || null} style={getStyles(stylesProps)} {...rest}>
          {children}
        </p>
      );
    case 'span':
      return (
        <span className={variant || null} style={getStyles(stylesProps)} {...rest}>
          {children}
        </span>
      );
    default:
      return children;
  }
};

export default Typography;
