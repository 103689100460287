const IconArrowButton = ({
  fill = '#001F19',
  className,
  dataTestid = 'icon-arrow-button'
}) => (
  <svg
    data-testid={dataTestid}
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      d="M16.9563 13.0843V13.0784C16.9779 13.0284 16.9902 12.9755 16.9902 12.9167C16.9902 12.8579 16.9779 12.805 16.9563 12.755V12.7491C16.9316 12.6933 16.8976 12.6463 16.8544 12.6051L12.5048 8.46309C12.3227 8.28965 12.0294 8.28965 11.8504 8.46309C11.6713 8.63653 11.6682 8.9158 11.8504 9.0863L15.4097 12.4758H7.45329C7.19706 12.4758 6.99023 12.6727 6.99023 12.9167C6.99023 13.1607 7.19706 13.3577 7.45329 13.3577H15.4097L11.8504 16.7471C11.6682 16.9206 11.6682 17.1998 11.8504 17.3703C11.9399 17.4556 12.0603 17.4997 12.1776 17.4997C12.2949 17.4997 12.4153 17.4556 12.5048 17.3703L16.8544 13.2283C16.8976 13.1872 16.9316 13.1372 16.9563 13.0843Z"
      fill={fill}
    />
  </svg>
);

export default IconArrowButton;
